import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi! My name is Yin Lin (林胤).
I am a postdoctoral associate in Center for Theoretical Physics at MIT. I am also member of the NSF AI Institute for Artificial Intelligence and Fundamental Interactions. Previously, I obtained my physics Ph.D. degree from the University of Chicago in 2021 working with
Andreas Kronfeld in the Fermilab theory division.`}</p>
    <p>{`Quantum chromodynamics (QCD) is part of the Standard Model particle physics that describes the strong force between quarks and gluons that constitute nuclei.
It is a notoriously difficult theory to solve, and lattice QCD (LQCD) provides the only systematic approach that allows us to explore the dynamics of QCD numerically with Monte Carlo simulations on
computing clusters.
My current research focuses on calculating the properties of protons, neutrons, and other heavier particles with LQCD and applying machine learning algorithms to accelerate existing LQCD simulations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      